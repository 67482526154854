import * as React from "react"
import { Link } from "gatsby"
import Layout from '../components/layout'


const NotFoundPage = (props) => {
  return (
    <Layout location={props.location}>
      <section className='bg-green'>
        <div className='container flex flex-col items-center justify-center min-h-screen mx-auto text-center'>
          <h1 className='mb-20 text-6xl font-bold'>
            404 - Página no encontrada
          </h1>
          <Link to='/' className='text-xl font-bold underline'>
            Volver al inicio
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
